.pub-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
	margin-top: 10px;
    max-width: 940px;
	padding:10px;
}

.pub-outer {
	margin: auto;
	max-width: 940px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pub-title {
    font-size: 25px;
}

.pub-authors {
    font-size: 17px;
}

.pub-conference {
    font-style: italic;
}

.pub-links {
    font-size:  17px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.pub-date-text {
    font-size:  19px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: var(--accent-color);
}


.pub-a {
    margin-right: 5px;
}

.pub-author-conf {
    font-size: 17px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.divider {
    background-color: var(--accent-color);
    border: 10px;
    height: 5px;
    width: 90%;
}

.pub-a:link {
    color: var(--pub-link-color);
    text-decoration: none;
  }
  
/* visited link */
.pub-a:visited {
    color: var(--pub-link-color);
}
  
/* mouse over link */
.pub-a:hover {
    color: var(--pub-link-hover-color);
    text-decoration: underline;
}
  
/* selected link */
.pub-a:active {
  color: black;
}

@media all and (max-width: 700px) {
    .pub-outer {
        margin-left: 20px;
        margin-right: 20px;
    }

    .pub-item {
        max-width: 100%;
    }

    .pub-links {
        font-size:  2.5vw;
    }

    .pub-date-text {
        font-size:  2.5vw;
    }


    .pub-author-conf {
        font-size: 2.5vw;
    }

    .pub-title {
        font-size: 4vw;
    }
}
