.footer {
    left: 0;
    bottom: 0;
    padding: 10px;
    background-color: var(--accent-two-color);
    color: var(--background-color);
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

.footer-link {
    padding: 5px;
    font-size: 15px;
}

.foot-a:link {
    color: #314455;
  }
  
  /* visited link */
.foot-a:visited {
    color: #314455;
}
  
.foot-a:hover {
    color: rgb(252, 238, 238);
}
  
.foot-a:active {
    color: black;
}
