.header {
  background-color: var(--header-color);
  min-height: 3vh;
  color: var(--background-color);
}

.header-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
  padding: 10px;
}


.header-name {
    margin: 10px;
    margin-left: 5px;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    width: 75%;
}

.header-link-divider {
    margin: 5px;
}

.header-links {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-right: 0vi;
    width: 25%;
}

.header-link {
    flex-grow: 1;
    padding: 5px;
}

.head-a:link {
	text-decoration: none;
    color: var(--header-link-color);
  }
  
/* visited link */
.head-a:visited {
  color: var(--header-link-color);
}
  
/* mouse over link */
.head-a:hover {
  color: var(--header-link-hover-color)
}
  
/* selected link */
.head-a:active {
  color: black;
}


.fa-head {
  color: #314455;
  text-align: center;
  text-decoration: none;
}

.fa-head:visited {
  	text-align: center;
  	text-decoration: none;
  	color: #314455;
}

.fa-head:hover {
  	color: rgb(252, 238, 238);
}

.fa-head:active {
  color: black;
}





@media all and (max-width: 900px) {
  .header{
    align-items: center;
    justify-content: space-evenly;
  }

  .header-text{
    margin-left: 20px;
    margin-right: 20px;
  }
  .header-name {
    font-size: 4vw;
  }

  .header-links{
    font-size: 3vw;
    justify-content: space-evenly;
    padding-right: 8vi;
  }
}
