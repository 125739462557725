.Self-Block {
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 25px;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.welcome-text {
    text-align: center;
}

.Intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.face-img {
    border-radius: 5%;
    max-width:60%;
    border:2px solid var(--accent-color);
    margin-left: 20%;
    margin-right: 20%;
}

.notice{
    text-align: center;
}

.IntroSection {
    flex-direction: row;
    justify-content: center;
    align-items: center;
	display: flex;
    flex-grow: 2;
    margin-top: 15px;
    width: 100%;
}

.IntroText {
    width: 70%;
}

.face {
	flex-direction: column;
    justify-content: center;
    align-items: center;
	display: flex;
    flex-grow: 1;
    margin-top: 15px;
    width: 50%;
}

.faceImg {
    align-items: center;
    justify-content: center;
    justify-self: center;
    align-self: center;
    width: 100%;
}

.faceLinks {
    width: 100%;
    text-align: center;
    font-size: 35px;
}

.Self-Block{
	font-size: 23px;
}

.text-a:link {
    color: var(--main-link-color);
    text-decoration: none;
}

/* selected link */
.text-a:active {
    color: black;
    text-decoration: none;
}
  
/* visited link */
.text-a:visited {
    color: var(--main-link-color);
    text-decoration: none;
}

/* mouse over link */
.text-a:hover {
    color: var(--main-link-hover-color);
    text-decoration: underline;
}

.fa {
    color: var(--main-link-color);
    text-align: center;
    text-decoration: none;
}

.fa:visited {
    text-align: center;
    text-decoration: none;
    color: var(--main-link-color);
}

.fa:active {
    color: black;
}

.fa:hover {
    color: var(--main-link-hover-color);
}

.ai {
    color: var(--main-link-color);
    text-align: center;
    text-decoration: none;
  }
  
.ai:visited {
    text-align: center;
    text-decoration: none;
    color: var(--main-link-color);
}

.ai:active {
    color: black;
}

.ai:hover {
    color: var(--main-link-hover-color)
}

li {
	padding: 6px;
}

.pub-links {
    font-size:  17px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media all and (max-width: 900px) {

    .Self-Block{
        align-items: center;
        justify-content: center;
        max-width: 100%;
        margin-left: 20px;
        margin-right: 20px;
		font-size: 4vw;
    }

    .IntroSection{
        flex-wrap: wrap;
    }

    .IntroText{
        width: 100%;
    }

    .face{
        width: 100%;
    }

    .face-img{
        max-width: 40%;
        margin-left: 30%;
    }

    .faceLinks{
        font-size: 6vi;
    }
}


