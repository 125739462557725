.pics-outer {
	margin: auto;
}

.pics-intro {
    font-size: 20px;
	padding: 20px;
}

.divider {
    background-color: var(--accent-color);
    border: 3px;
    height: 2px;
    width: 100%;
}

/*
.pics-gallery {
	margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
    padding: 50px;
}
*/

.pics-a {
    margin: 10px;
    padding: 10px;
}

.pics-desc {
    color: #314455;
}

.pics-img {
    padding: 5px;
	width: 300px;
	height: 200px;
    border-radius: 5%;
} 

@media all and (max-width: 900px) {
  .pics-outer {
      margin-left: 10px;
      margin-right: 10px;
  }

  .pics-img {
	height: 100px;
	width: 150px;
    border-radius: 10%;
  }

  .pics-intro {
      font-size: 2.5vw;
  }
}
