.content {
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1500px;
    display: flex;
    flex-wrap: wrap;
    text-align: justify;
    font-size: 1.5vw;
    flex-direction: column;
    justify-content: center;
}

@media all and (max-width: 900px) {

    .content {
        max-width: 1000px;
    }
}