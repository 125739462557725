  
.Notebook {
    font-size: 16pt;
	max-width: 100%;
    background: rgb(250, 250, 245) !important;
	padding-top: 10px;
	padding-left: 30px;
	padding-right: 30px;
	border-radius: 15px;
	color: #314455;
    text-align: left;
	font-family: Georgia, Georgia, serif;
}

.Notebook img {
    max-width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
    display: block;
	box-shadow:  3px 3px 5px 4px #ccc;
}

.Notebook p {
	line-height: 1.5;
}

.Notebook p code {
    color: #DF4034;
    background: rgb(230, 230, 230) !important;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    border-color: #e9e9e9;
    padding: 0px 3px;
}

.Notebook-code pre {
    background: rgb(230, 230, 230) !important;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    border-color: #e9e9e9;
    font-size: 11pt;
}

.Notebook-output pre {
	color: black;
    background: rgb(230, 230, 230) !important;
    padding: 0.5em;
    border: 0;
}


.Notebook table {
	margin: auto;
	padding: .5rem;
}

.Notebook td {
	padding: .5rem;
}

.Notebook td, th {
  padding: .5rem;
  border: 1px solid #ccc;
  text-align: center;
}

.Notebook th {
  background: rgb(230, 230, 230) !important;
  border-color: white;
}


@media all and (max-width: 900px) {

    .Notebook {
        font-size: 3.8vw;
    }
}